import React from "react";

// Customizable Area Start
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import CustomToaster from "../../../components/src/CustomToaster";
import { goToPrevPage } from "../../../components/src/Utilities";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { Box, Radio,
    FormControlLabel, List,
    ListItem,
    ListItemText,
    Divider,
      Container, Typography as Mtypography, IconButton, ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { navLinkIcon } from "./assets";

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

const webStyles: { [key: string]: React.CSSProperties } = {
    containerStyle: { marginTop: "50px"},
  
    socialData: {
      marginRight: "40px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column"
    },
  
    btnSubmitStyle: {
      background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
      color: "#fff",
      fontSize: "20px",
      fontWeight: 700,
      display: "inline-block",
      width: "100%",
      maxWidth: "100%",
      minWidth: "100%",
      borderRadius: "8px",
      height: "56px",
    },
  
    profileBtnStyle: {
      width: "196px",
      height: "44px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      marginRight: "15px"
    },
  
    socialNumberStyle: {
      color: "#292B29",
      fontWeight: 600,
      fontSize: "24px"
    },
    socialFigureName: {
      color: "#292B29",
      fontWeight: 400,
      fontSize: "20px"
    },
  
    fieldGridStyle: {
      marginBottom: "25px"
    },
  
    outlinedIcon: {
      height: "50px",
      width: "50px",
      background: "transparent",
      borderRadius: "8px",
      border: "1px solid #925B5B",
      padding: "10px",
      lineHeight: "32px",
      marginRight: "15px",
    },
  
    IconBtnStyle: {
      height: "50px",
      width: "50px",
      background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
      borderRadius: "8px",
      padding: "10px",
      lineHeight: "32px",
      marginRight: "15px",
    },
    formHelperStyle: {
      marginLeft: 0,
      marginTop: "5px",
      fontSize: "14px",
    },
  
    fieldIconStyle: {
      marginLeft: "10px"
    },
  
    titleStyle: {
      fontWeight: "bold",
      fontSize: "24px",
      color: "#282627",
    },
  
  };

const StyledTypography = styled(Mtypography)({
    "&.label-title": {
        width:"350px",
      fontSize: "28px",
      fontWeight: 600,
      color: "#0F172A",
      margin: "25px 0px",
    //   paddingInline: "20px",
      "@media (max-width: 1000px)": {
        width:"350px",
        // paddingInline: "15px",
        fontSize: "20px",
      },
      "@media (max-width: 500px)": {
  
      },
    },
    "&.desc-menu": {
      display: "flex",
      alignItems: "center",
      fontSize: "20px",
      color: "#0F172A",
      fontWeight: 400,
      margin: "10px 0px",
      "@media (max-width: 1000px)": {
        fontSize: "18px",
      },
      "@media (max-width: 525px)": {
        fontSize: "16px",
      },
      "& span": {
        marginLeft: "15px",
        "@media (max-width: 1000px)": {
          marginLeft: "10px"
        },
      },
    }
  });

  const CustomRadio = styled(Radio)({
    color: "#925B5B", // Default color
    "&.Mui-checked": {
      color: "#4CAF50", // Green color when selected
    },
  });

// Customizable Area End

import PaymentMethodsController, {
  Props,
  configJSON,
} from "./PaymentMethodsController";


export default class PaymentMethods extends PaymentMethodsController {
  
  // Customizable Area Start

    paymentMethodsName = [
      { id: "credit", label: "Credit/Debit Cards" },
      { id: "apple", label: "Apple Pay" },
      { id: "stripe", label: "Stripe"},
    ];
  // Customizable Area End

  render() {
    // Customizable Area Start

    console.log("stripeErrorMessage",configJSON.urlScheme)

    const { navigation, id } = this.props;
    const { isLoading,selectedMethod } = this.state;


    return (
      <Box data-test-id="profile-container">
        <NavigationMenu navigation={navigation} id={id} />
        <ThemeProvider theme={theme}>
          <Container style={webStyles.containerStyle}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box display="flex" alignItems="center">
                <IconButton
                  data-test-id="back-btn"
                  style={webStyles.IconBtnStyle}
                  onClick={() => goToPrevPage()}
                >
                  <ArrowBackIcon htmlColor="#fff" />
                </IconButton>
                <Typography style={webStyles.titleStyle}>
                  Payments
                </Typography>
              </Box>
             
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
              style={{ marginTop: "50px" }}
            >
              <Box
                style={{
                  position: "relative",
                  height: "156px",
                  width: "156px",
                //   borderRadius: "50%",
                //   backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <StyledTypography className="label-title">
                      PAYMENTS METHODS
                </StyledTypography>
                <Typography 
                style={{
                    width: "max-content",
                    position: "absolute",
                    left: "0", 
                    transform: "translateX(-50%)", 
                  }}>
                  All transactions are secure and encrypted
                </Typography>
              </Box>
              
                
            
            </Box>
            <Box
      style={{
        maxWidth: 400,
        margin: "auto",
        padding: "2px",
        boxShadow: "3px",
        borderRadius: "2px",
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Payment Methods
      </Typography>
      <List>
        {this.paymentMethodsName.map((method) => (
          <div key={method.id}>
            <ListItem
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                value={method.id}
                control={
                    <CustomRadio
                    checked={selectedMethod === method.id}
                    onChange={this.handleRadioChange}
                  />
                }
                label=""
              />
              {/* <ListItemIcon>icon</ListItemIcon> */}
              <ListItemText
                primary={method.label}
                style={{ marginLeft: -2, flex: 1 }}
              />
               <img
                src={navLinkIcon}
                style={{height: "15px", width:"12px"}}
                alt=""
            />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Box>
            <Loader isLoading={isLoading} />
          </Container>
        </ThemeProvider>
        <CustomToaster />
      </Box>
    );
    // Customizable Area End
  }
}

