import React from "react";
import {
  Box,
} from "@material-ui/core";
import CustomSkeleton from "./CustomSkeleton";

interface Props {
}


const LoadingPostCard = (props: Props) => {

  return (
    <Box style={webStyles.containerStyle}>
      <Box>
        <CustomSkeleton variant={"rect"} style={webStyles.imgSkeleton} />
      </Box>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"} marginLeft={"15px"}>
        <Box>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"fit-content"}>
            <Box display={"flex"} alignItems={"center"}  >
              <CustomSkeleton variant="circle" style={webStyles.avatarStyle} />
              <CustomSkeleton variant="rect" style={webStyles.postDataStyle} />
            </Box>
            <CustomSkeleton variant="circle" style={{ cursor: "pointer", width: "32px", height: "32px" }} />
          </Box>
          <CustomSkeleton variant="rect" style={webStyles.descriptionStyle} />
          <CustomSkeleton variant="rect" style={webStyles.descriptionStyle} />
        </Box>
        <Box>
          <Box display={"flex"} marginTop="15px" marginBottom={"15px"}>
            <CustomSkeleton variant="circle" />
            <CustomSkeleton variant="circle" style={webStyles.iconStyles}/>
            <CustomSkeleton variant="circle" style={webStyles.iconStyles}/>
          </Box>
          <CustomSkeleton variant="rect" />
          <CustomSkeleton variant="rect" style={webStyles.commentSkeleton} />
          <CustomSkeleton variant="rect" style={webStyles.commentSkeleton} />
        </Box>
      </Box>
    </Box>
  )

};

const webStyles: { [key: string]: React.CSSProperties } = {
  commentSkeleton: { marginTop: "5px" },
  imgSkeleton: { width: "542px", height: "431px", borderRadius: "32px" },
  containerStyle: {
    display: "flex",
    width: "880px",
    marginBottom: "75px"
  },
  iconStyles: {
    marginLeft: "20px"
  },
  descriptionStyle:
    { color: "#0F172A", fontSize: "16px", marginTop: "20px", width: "100%" },
  avatarStyle:
  {
    height: "44px",
    width: "44px",
    border: "2px solid #F3E1DC",
  },
  postDataStyle: {
    marginLeft: "16px",
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 600,
    cursor: "pointer"
  }

}

export default LoadingPostCard;
