// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { ModalContainer } from "react-router-modal";
import { Outlet, useLocation } from 'react-router-dom';
import  WebRoutesGenerator  from '../../components/src/NativeWebRouteWrapper';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary.web";
import ApplePayIntegration from "../../blocks/applepayintegration/src/ApplePayIntegration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail.web";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Videos from "../../blocks/videos/src/Videos";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import EditUserProfile from "../../blocks/user-profile-basic/src/EditUserProfile.web";
import Settings2 from "../../blocks/settings2/src/Settings2.web";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import Repost from "../../blocks/repost/src/Repost.web";
import RepostList from "../../blocks/repost/src/RepostList.web";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Feedpage from "../../blocks/landingpage/src/FeedPage.web";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import PostCreation from "../../blocks/postcreation/src/PostCreation.web";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2.web";
import Loves from "../../blocks/likeapost2/src/Loves.web";
import ForgotPasswordBlock from '../../blocks/forgot-password/src/ForgotPasswordBlock.web'
import ForgotPasswordOTPBlock from '../../blocks/forgot-password/src/ForgotPasswordOTPBlock.web'
import NewPasswordBlock from '../../blocks/forgot-password/src/NewPasswordBlock.web'
import EmailAccountRegistrationBlock from  '../../blocks/email-account-registration/src/EmailAccountRegistration.web'
import PaymentMethods from '../../blocks/stripepayments/src/PaymentMethods.web'
import StripePayment from '../../blocks/stripepayments/src/StripePayments.web'

const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary/:navigationBarTitleText"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
ForgotPassword:{
 component:ForgotPasswordBlock,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTPBlock,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPasswordBlock,
path:"/ResetPassword"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions/:navigationBarTitleText"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Videos:{
 component:Videos,
path:"/Videos/:navigationBarTitleText?"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock/:navigationBarTitleText?"},
EditUserProfile:{
  component:EditUserProfile,
 path:"/EditUserProfile"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,  
path:"/CreateComment"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Repost:{
 component:Repost,
path:"/Repost/:navigationBarTitleText"},
RepostList:{
  component:RepostList,
 path:"/RepostList"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/otp-verification"},
EmailAccountRegistration:{
 component:EmailAccountRegistrationBlock,
path:"/EmailAccountRegistration"},
EmailRegistration:{
  component:EmailAccountRegistrationBlock,
path:'/EmailRegistration'  
},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Feedpage:{
  component:Feedpage,
 path:"/Feed"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},
StripePayment:{
  component:StripePayment,
 path:"/StripePayment"},
PaymentMethods:{
  component:PaymentMethods,
 path:"/PaymentMethods"},
Loves:{
  component:Loves,
 path:"/Loves/:navigationBarTitleText"},

  Home: {
    component: Splashscreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
        <BuilderProvider>
          <View style={{ height: '100%', width: '100%' }}>
            {/* <TopNav /> */}
            {WebRoutesGenerator({ routeMap })}
            <ModalContainer />
          </View>
        </BuilderProvider>
    );
  }
}

export default App;