import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Modal,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Typography,
  styled
} from "@material-ui/core";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { weddingImg } from "./assets";
import CustomSkeleton from "../../../components/src/CustomSkeleton";
import { crossIcon } from "../../../blocks/pushnotifications/src/assets";
import { goToPrevPage } from "../../../components/src/Utilities";
import { playIcon } from "../../../blocks/videos/src/assets";

const mockGalleryData = [
  {id: 1, type: "image"},
  {id: 2, type: "image"},
  {id: 3, type: "video"},
  {id: 4, type: "video"},
  {id: 5,type: "image"},
  {id: 6, type: "video"},
  {id: 7, type: "image"},
  {id: 8, type: "video"},
  {id: 9, type: "image"},
  {id: 10, type: "video"},
  {id: 11, type: "image"},
  {id: 12, type: "image"},
  {id: 13, type: "video"},
  {id: 14, type: "image"},
  {id: 15, type: "image"},
]

// Customizable Area End

import PhotoLibraryController, {
  Props,
  configJSON
} from "./PhotoLibraryController";

// Customizable Area Start

const StyledBox = styled(Box)({
  "&.media-container": {
    display: "flex",
    flexWrap: "wrap",
    "@media (max-width: 1225px)": {
      justifyContent: "center",
    },
  },
});

// Customizable Area End

export default class PhotoLibrary extends PhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getPostsGrid = () => {
    return (
      <StyledBox className="media-container">
            {this.state.libraryData.map(
              (item, index) => {
                return this.state.isLoading ? (
                  <Box
                    key={index}
                    className="media-container"
                    style={{ position: "relative", width: "fit-content" }}
                  >
                    <CustomSkeleton
                      variant="rect"
                      style={webStyles.mediaStyle}
                    />
                  </Box>
                ) : (
                  <Box
                    key={index}
                    className="media-container"
                    style={{ position: "relative", width: "fit-content" }}
                  >
                    {item.type === "image" ? <img src={item.image_uri} 
                    data-test-id="media-tile"
                    style={this.state.isFullScreen ? webStyles.fullScreenImg : webStyles.mediaStyle}
                    onClick={() => this.handleImageFullScreen(true)} alt="" /> : (
                      <video autoPlay style={this.state.isFullScreen ? webStyles.fullScreenImg : webStyles.mediaStyle} >
                        <source src={item.image_uri} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </Box>
                );
              }
            )}
            {this.state.isFullScreen && <img
              src={crossIcon}
              style={webStyles.crossIcon}
              onClick={() => this.handleImageFullScreen(false)}
              data-test-id="cross-btn"
              alt=""
            />}
          </StyledBox>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <NavigationMenu navigation={this.props.navigation} id={"1"} />
        <Container style={webStyles.containerStyle} data-test-id="library-container">
          <Box display={"flex"} alignItems={"center"}>
            <IconButton
              data-test-id="btn-back"
              style={webStyles.IconBtnStyle}
              onClick={() => goToPrevPage()}
            >
              <ArrowBackIcon htmlColor="#fff" />
            </IconButton>
            <Typography style={webStyles.titleStyle}>
              {configJSON.libraryTitle}
            </Typography>
          </Box>
          <Typography style={webStyles.subtitleStyle}>
            {this.state.currLibraryType === configJSON.libraryType.GALLERY ? configJSON.gallery : configJSON.post}
          </Typography>
          {this.getPostsGrid()}
        </Container>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles: { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px" },
  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
  },
  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },
  subtitleStyle: {
    fontSize: "20px",
    fontWeight: 700,
    margin: "40px 0px"
  },
  mediaStyle: {margin: "2px", cursor: "pointer", width: "229px", height: "229px"},
  crossIcon: {
    zIndex: 9999999,
    position: "absolute",
    top: "25px",
    right: "45px",
    height: "40px",
    width: "40px"
  },
  fullScreenImg: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
  },
  playIconStyles: {
    position: "absolute",
    top: "40%",
    right: "40%",
    cursor: "pointer",
    zIndex: 100000
  }
};
// Customizable Area End
