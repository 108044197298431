import React from "react";

// Customizable Area Start
import { Box, Button, Container, Grid, IconButton, InputAdornment, TextField, withStyles } from "@material-ui/core";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import CustomToaster from "../../../components/src/CustomToaster";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { imgPasswordInVisible, imgPasswordVisible } from "../../../blocks/email-account-registration/src/assets";
import CustomButton from "../../../components/src/CustomButton";
// Customizable Area End

export class NewPasswordBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderCustomCheckbox(isChecked:any) {
    return (
      <span
      style={{
        ...styles.checkbox,
        ...(isChecked ? styles.checkboxChecked : {}), 
      }}
    >
      <i style={styles.checkmark} /> 
    </span>
    );
  }
  renderPasswordCheckBoxes() {
    const { hasUpperCase, hasSpecialChar, hasNumber, hasMinLength } = this.state;

    return (
      <Box data-test-id="validation-checkbox">
        <div style={styles.container}>
          {this.renderCustomCheckbox(hasUpperCase)}
          <span style={styles.label}>At least one capital letter</span>
        </div>
        <div style={styles.container}>
          {this.renderCustomCheckbox(hasSpecialChar)}
          <span style={styles.label}>At least one special character</span>
        </div>
        <div style={styles.container}>
          {this.renderCustomCheckbox(hasNumber)}
          <span style={styles.label}>At least one number</span>
        </div>
        <div style={styles.container}>
          {this.renderCustomCheckbox(hasMinLength)}
          <span style={styles.label}>Minimum character length is 8 characters</span>
        </div>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <Box style={webStyles.parentBox} >
          <Container>
            <Grid container spacing={4} >
            <Grid item sm={6} md={6} style={{margin:'0 auto'}} >
                <Box style={{textAlign:'center',marginBottom:'40px'}}>
                  <h1 style={webStyles.titleText}>Reset Password</h1>
                  <p style={webStyles.plaintext}>Enter new password for {this.state.currEmail}</p>
                </Box>

                <Box style={webStyles.labelField}>
                <label style={webStyles.textLabel} htmlFor="password">New Password</label>

              <TextField
              // style={webStyles.textField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><LockOutlinedIcon  style={{color:'#DADADA'}} /></InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {this.state.showPassword ? <img src={imgPasswordInVisible}/> : <img src={imgPasswordVisible} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter new password"
                  variant="outlined"
                  type={this.state.showPassword ? 'text' : 'password'}
                  data-test-id="txtInputPassword"
                  fullWidth
                  value={this.state.password}
                  name="password"
                  onChange={this.handlePasswordChange}
                  onFocus={this.handleToggleCriteriaVisibility}
                  onBlur={this.handleToggleCriteriaVisibility}
                  error={!!this.state.passwordError}
                  helperText={this.state.passwordError && <span style={webStyles.errorInputText}>{this.state.passwordError}</span>}
                />
              </Box>
              <Box style={webStyles.labelField}>
                <label style={webStyles.textLabel} htmlFor="password">Confirm  Password</label>

              <TextField
              // style={webStyles.textField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><LockOutlinedIcon  style={{color:'#DADADA'}} /></InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleToggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {this.state.showConfirmPassword ? <img src={imgPasswordInVisible}/> : <img src={imgPasswordVisible} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Confirm new password"
                  variant="outlined"
                  type={this.state.showConfirmPassword ? 'text' : 'password'}
                  data-test-id="txtInputPsword"
                  fullWidth
                  value={this.state.confirmPassword}
                  name="password"
                  onChange={this.handleConfirmPasswordChange}
                  error={!!this.state.confirmPasswordError}
                  helperText={this.state.confirmPasswordError && <span style={webStyles.errorInputText}>{this.state.confirmPasswordError}</span>}
                />
              </Box>
              <div style={{marginBottom:'40px'}} >
                 {this.renderPasswordCheckBoxes()}
              </div>
              <Box>
                <CustomButton style={{ ...webStyles.buttonStyle, textTransform: 'none' }} disabled={Boolean(this.state.isPasswordInvalid) || Boolean(this.state.confirmPasswordError) || this.state.confirmPassword === ""} data-test-id="setNewPassword" onClick={this.handleSetNewPass} variant={"contained"} btnText={"Set New password"} color={"primary"} />
              </Box>
              <Box style={{textAlign:'center' as 'center'}} >
                  <p style={webStyles.plaintext} >Back to <span  data-test-id="" onClick={this.handleBacktoSignIn} style={webStyles.anchorTagStyles}>Sign In</span> </p>
              </Box>
            </Grid>
           </Grid>
        </Container>
        <CustomToaster />
       </Box>     
      </>
      // Customizable Area End
    );
  }

}

 // Customizable Area Start
 const webStyles = {
  parentBox:{
    padding: '80px 0'
  },
  backBtn:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    minWidth:'44px',
    borderRadius:'8px'
  },
  anchorTagStyles:{
    color:'#925B5B',
    fontSize:'16px',
    fontWeight:600,
    lineHeight:'5px',
    cursor: "pointer",
    textDecoration: "underline"
  },
  titleText:{
    fontSize:'48px',
    fontWeight:700,
    marginBottom:'10px'
  },
  plaintext:{
    fontSize:'16px',
    fontWeight:400,
    lineHeight:'24px'
  },
  labelField:{
    marginBottom:'30px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap:'10px'
  },
  textLabel:{
    fontSize:'16px',
    lineHeight:'24px',
    fontWeight:700,
  },
  buttonStyle:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    fontSize:'20px',
    fontWeight:700,
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    padding:'16px 0',
    borderRadius:'8px',
  },
  errorInputText:{
    color:'red',
    fontSize:'14px'
 },
 passwordCriteria:{
  display :'flex',
  gap:'5px',
  alignItems:'center'
},
 }
 const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  checkbox: {
    width: '20px',
    height: '20px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px', 
    backgroundColor: '#94A3B8', 
    marginRight: '8px',
    position: 'relative' as 'relative',
  },
  checkboxChecked: {
    backgroundColor: '#34D399', 
  },
  checkmark: {
    width: '7px',
    height: '10.5px',
    border: 'solid #fff',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
    display: 'inline-block',
    marginBottom:"5px"
  },
  label: {
    fontSize: '16px',
    color: '#1F2937', 
  },
};

 export default NewPasswordBlock;
 // Customizable Area End
