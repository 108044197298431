import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef } from "react";

export interface IAccountAttributes {
  activated: boolean;
  country_code: string | null;
  email: string;
  name: string;
  first_name: string | null;
  full_phone_number: string;
  last_name: string | null;
  phone_number: string | null;
  type: string;
  profile_handle: string;
  user_location: string;
  bio: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  profile_image: string | null;
  post_count: number;
  followers: number;
  following: number;
  full_name: string | null
}

export interface IAccountData {
  id: string;
  type: string;
  attributes: IAccountAttributes;
}

// Customizable Area End

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  profileData: IAccountData | undefined;
  isProfileValidationLoading: boolean;
  isValidationError: boolean;
  profileValidationMsg: string | undefined;
  openEditImgModal: boolean;
  isWebCamOpen: boolean;
  selectedFile: File[];
  selectedMethod: string | null;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentMethodsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInputRef = createRef<HTMLInputElement>();
  // Customizable Area Ends

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      profileData: undefined,
      isProfileValidationLoading: false,
      isValidationError: false,
      profileValidationMsg: undefined,
      openEditImgModal: false,
      isWebCamOpen: false,
      selectedFile: [],
      selectedMethod: null,
      // Customizable Area End
    };
    

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  getProfileId: string = "";
  validateHandleId: string = "";
  updateProfileDetailsId: string = "";
  editProfileImg: string = "";

  handleRadioChange = (event: { target: { value: string; }; }) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      selectedMethod: prevState.selectedMethod === value ? null : value, 
    }));
  };



  // Customizable Area End
}
