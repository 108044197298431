import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-hot-toast";

export const checkboxIcon = {pass: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" rx="6" fill="#34D399"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0611 5.42229C15.5183 5.7322 15.6376 6.35402 15.3277 6.81115L10.2432 14.3112C10.0771 14.5561 9.8111 14.7149 9.51671 14.7449C9.22232 14.7748 8.92977 14.6729 8.71777 14.4664L4.80234 10.6535C4.40667 10.2682 4.39827 9.63511 4.78358 9.23944C5.16888 8.84377 5.80199 8.83537 6.19766 9.22068L9.25771 12.2006L13.6723 5.68886C13.9822 5.23173 14.604 5.11238 15.0611 5.42229Z" fill="white"/>
  </svg>,
    fail: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="6" fill="#94A3B8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0611 5.42229C15.5183 5.7322 15.6376 6.35402 15.3277 6.81115L10.2432 14.3112C10.0771 14.5561 9.8111 14.7149 9.51671 14.7449C9.22232 14.7748 8.92977 14.6729 8.71777 14.4664L4.80234 10.6535C4.40667 10.2682 4.39827 9.63511 4.78358 9.23944C5.16888 8.84377 5.80199 8.83537 6.19766 9.22068L9.25771 12.2006L13.6723 5.68886C13.9822 5.23173 14.604 5.11238 15.0611 5.42229Z" fill="white"/>
    </svg>,
    check: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="6" fill="url(#paint0_linear_19355_18896)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0611 5.42229C15.5183 5.7322 15.6376 6.35402 15.3277 6.81115L10.2432 14.3112C10.0771 14.5561 9.8111 14.7149 9.51671 14.7449C9.22232 14.7748 8.92977 14.6729 8.71777 14.4664L4.80234 10.6535C4.40667 10.2682 4.39827 9.63511 4.78358 9.23944C5.16888 8.84377 5.80199 8.83537 6.19766 9.22068L9.25771 12.2006L13.6723 5.68886C13.9822 5.23173 14.604 5.11238 15.0611 5.42229Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_19355_18896" x1="20" y1="10" x2="2.60204e-08" y2="10" gradientUnits="userSpaceOnUse">
    <stop stop-color="#BD9C9B"/>
    <stop offset="1" stop-color="#925B5B"/>
    </linearGradient>
    </defs>
    </svg>
  }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  emailError:string;
  password: string;
  passwordError:string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  showPassword: boolean,
  showCriteria: boolean,
  hasUpperCase: boolean,
  hasSpecialChar: boolean,
  hasNumber: boolean,
  hasMinLength: boolean,
  isChecked:boolean,
  showAlertBox:boolean
  isPasswordInvalid: boolean;
  isLoading: boolean;
  signUpErrorResponse: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  signupApiCallId:string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // checkEmailValidation:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      emailError: "",
      password: "",
      passwordError:'',
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      showPassword: false,
      showCriteria: false,
      hasUpperCase: false,
      hasSpecialChar: false,
      hasNumber: false,
      hasMinLength: false,
      isChecked: false,
      showAlertBox:false,
      isPasswordInvalid: false,
      isLoading: false,
      signUpErrorResponse: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = configJSON.validPasswordReg;
    this.emailReg = configJSON.validEmailRegex;

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.checkEmailValidation = this.checkEmailValidation.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
    this.closeErrorPopup = this.closeErrorPopup.bind(this);
    this.gotoLoginPage = this.gotoLoginPage.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    this.SignupApiCallResponse(apiRequestCallId,responseJson)
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(){
    super.componentDidMount();
    this.getEmail();
    let user: any = localStorage.getItem("currUser") || "";
    let isPolicyAgreed = this.parseJson("isPolicyAgreed");
    let isTermsAgreed = this.parseJson("isTermsAgreed");
    this.setState({isChecked: (isPolicyAgreed && isTermsAgreed)})

    if(user) {
      user = JSON.parse(user) 
      this.setState({email: user.email, password: user.password})
    }
  }

  parseJson = (key:string) => {
    let exists = sessionStorage.getItem(key)
    if(exists) {
      JSON.parse(exists)
    }
    return exists === "true" ? true : false;
  }

  async componentWillUnmount(){
    await removeStorageData("currEmail")
  }

  SignupApiCallResponse= async(apiRequestCallId:any,responseJson:any)=>{
    if(apiRequestCallId && responseJson){
      this.setState({
        isLoading: false
      })
      if (apiRequestCallId === this.signupApiCallId){
        if(!responseJson.errors){
          const signupToken= responseJson.meta.token
          await setStorageData('auth_Token',signupToken)
          await removeStorageData("currEmail")
          await setStorageData('auth_Token',signupToken)
          toast.success("Account created! Please check your email for activating your account")

          this.setState({
            email:'',
            password:'',
            passwordError: "",
            emailError: "",
            hasUpperCase: false,
            hasMinLength: false,
            hasNumber: false,
            hasSpecialChar: false,
            isChecked: false,
            signUpErrorResponse: ""
          })
        } else if(responseJson.errors) {
          this.setState({signUpErrorResponse: responseJson.errors[0].account})
        }
      }
  }
  }
gotoLandingPage(){
  const landingPage = new Message(getName(MessageEnum.NavigationMessage));
    landingPage.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    landingPage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(landingPage); 
}
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };


  checkPasswordValidation (){
    console.log('hello')
  }
  checkEmailValidation(event:React.ChangeEvent<HTMLInputElement>) {
    const email = event.target.value.trim();
    this.setState({ email });

    if (this.isStringNullOrBlankCheck(email)) {
      this.setState({ emailError:configJSON.emailRequiredMessage });
    } else if (!this.isValidEmailCheck(email)) {
      this.setState({ emailError: configJSON.emailValidMessage });
    } else {
      this.setState({ emailError: "" });
    }
  }

  getEmail = async () => {
    const currEmail = await getStorageData("currEmail")
    if (currEmail){
      this.setState({
        email: currEmail
      })
      if (!this.isValidEmailCheck(currEmail)) {
        this.setState({ emailError: configJSON.emailValidMessage });
      } else {
        this.setState({ emailError: "" });
      }
    }
  }

  isValidEmailCheck(email: string) {
    return configJSON.validEmailRegex.test(email)
  }

  isStringNullOrBlankCheck(str: string) {
    return str === null || str.length === 0;
  }

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    this.setState({ password }, () => this.validatePassword(password));
    this.setState({showCriteria:true})

  };

  handleTogglePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleToggleCriteriaVisibility = () => {
    this.setState({ showCriteria: !!this.state.password });
  };

  validatePassword = (password: string) => {
    this.setState({
      hasUpperCase: configJSON.upperCaseLetter.test(password),
      hasSpecialChar: configJSON.specialLetter.test(password),
      hasNumber: configJSON.hasNum.test(password),
      hasMinLength: password.length >= 8,
      passwordError: this.isStringNullOrBlankCheck(password)
        ? configJSON.passwordRequiredMessage
        : "",
      isPasswordInvalid:
        !configJSON.upperCaseLetter.test(password) ||
        !configJSON.specialLetter.test(password) ||
        !configJSON.hasNum.test(password) ||
        password.length < 8,
    });
  };

  handleCheckboxChange(event: any) {
    sessionStorage.setItem("isTermsAgreed", JSON.stringify(event.target.checked))
    sessionStorage.setItem("isPolicyAgreed", JSON.stringify(event.target.checked))

    this.setState({ isChecked: !this.state.isChecked });
    this.setState({ showAlertBox: false });
  }
 
  handleCreateAccount (){
    const {email,password,isChecked} = this.state
    this.setState({
      emailError: this.isStringNullOrBlank(email) ? configJSON.emailRequiredMessage : "",
      passwordError: this.isStringNullOrBlank(password) ? configJSON.passwordRequiredMessage : "",
      showAlertBox: !isChecked
     })

     if (this.isStringNullOrBlank(email) || this.isStringNullOrBlank(password) || !isChecked || this.state.isPasswordInvalid){
      return;
     }
     setStorageData("currUser", this.state.email)
     this.SignupApiCall();
}
  closeErrorPopup(){
    this.setState({showAlertBox:false})
  }

  SignupApiCall = async () => {
    const method = "POST";
    const header = {
      "Content-Type": 'application/json',
      "platform": 'web'
    };
    
    const signupBody = {
      
        "data":{
            "type": "email_account", //sms_account,email_account
            "attributes": {
                "email":this.state.email,
                "full_phone_number": "",
                "password": this.state.password
            }
        }
    
  }
    const apiUrl = "account_block/accounts";
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(signupBody)
    )
    this.setState({
      isLoading: true
    })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  gotoLoginPage(){
    const signinPage = new Message(getName(MessageEnum.NavigationMessage));
    signinPage.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    signinPage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(signinPage); 
  }

  navigateToPage = async (page: string) => {
    await setStorageData("currUser", JSON.stringify({email: this.state.email, password: this.state.password}))
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditions"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), page);
    this.send(message);
  };

  // Customizable Area End
}
