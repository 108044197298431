import React from "react";

// Customizable Area Start
import { Box, Button, Container, Grid, InputAdornment, TextField, withStyles } from "@material-ui/core";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import CustomToaster from "../../../components/src/CustomToaster";
import CustomButton from "../../../components/src/CustomButton";
// Customizable Area End

export class ForgotPasswordBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={{position:'relative' as 'relative'}} >
          <Box style={{position:'absolute' as 'absolute',top:'30px',left:'30px'}} >
            <Button style={webStyles.backBtn} onClick={this.handleBacktoSignIn} ><ArrowBackIcon /></Button>
          </Box>
        </Box>
        <Box style={webStyles.parentDiv} >
          <Container>
            <Grid container spacing={4} >
              {
                !this.state.showRecovery ? (
                <Grid item sm={6} md={6} style={{margin:'0 auto',maxWidth: "553px", width: "100%"}} >
                <Box style={{textAlign:'center', marginBottom: "52px"}}>
                  <h1 style={webStyles.titleText}>Forgot Password</h1>
                  <p style={webStyles.plaintext}>No worries, we’ll send a recovery link to your email.</p>
                </Box>
                <Box display={"flex"} flexDirection={"column"} style={{gap: "12px"}}>
                <Box style={webStyles.labelField}>
                  <label style={webStyles.textLabel} htmlFor="email">Email address</label>
                  <TextField
                    style={webStyles.textInputField}
                    placeholder="Enter Email address"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><MailOutlineIcon  style={{color:'#DADADA'}} /></InputAdornment>
                    }}
                    variant="outlined"
                    type="text"
                    data-test-id="txtInputEmailLogin"
                    fullWidth
                    value={this.state.email}
                    name="email"
                    onChange={this.checkEmailValidation}
                    error={!!this.state.emailError}  // helperText={this.state.emailError}
                    helperText={this.state.emailError && <span style={webStyles.errorInputText}>{this.state.emailError}</span>}
                  />
                </Box>
              <Box>
                <Box>
                  <CustomButton style={webStyles.buttonStyle} disabled={Boolean(this.state.emailError) || this.state.email === ""} data-test-id="send-recovery-btn" onClick={this.handleSendRecoveryMail} variant={"contained"} color={"primary"} btnText={"Send a recovery link"} />
                </Box>
                <Box style={{textAlign:'center' as 'center'}} >
                    <p style={webStyles.plaintext} >Back to <a href="" data-test-id="" onClick={this.handleBacktoSignIn} style={webStyles.anchorTagStyles}>Sign In</a> </p>
                </Box>
              </Box>
                </Box>
              </Grid>
              ): 
              <Grid item sm={6} md={6} style={{margin:'0 auto', maxWidth: "553px", width: "100%"}} >
                <Box style={{textAlign:'center',marginBottom:'40px'}}>
                  <h1 style={webStyles.titleText}>Forgot Password</h1>
                </Box>
              <Box style={{textAlign:'center', marginBottom:'40px'}} >
                  <h4 style={{fontSize:'24px'}}>Check your email</h4>
                  <p style={webStyles.plaintext}>We have sent a password reset <br/> link to {this.state.email}</p>
                  <span data-test-id="resend-email" style={webStyles.anchorResendBtn} onClick={this.handleResendEmailLink}>Resend email</span>
              </Box>
              
              <Box>
                <Button style={webStyles.buttonStyle}  data-test-id="back-to-sign-in" onClick={this.handleBacktoSignIn}>Back to Sign In</Button>
              </Box>

              
              </Grid>
              }
            </Grid>
            <CustomToaster/>
          </Container>
        </Box>
      </>
      // Customizable Area End
    );
  }

}

 // Customizable Area Start
 const webStyles = {
  parentDiv:{
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  titleText:{
    fontSize:'48px',
    fontWeight:700,
    marginBottom:'10px'
  },
  plaintext:{
    fontSize:'16px',
    fontWeight:400,
    lineHeight:'24px'
  },
  labelField:{
    marginBottom:'30px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap:'10px'
  },
  textLabel:{
    fontSize:'16px',
    lineHeight:'24px',
    fontWeight:700,
  },
  textInputField:{
    height:'56px !important',
  },
  backBtn:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    minWidth:'44px',
    borderRadius:'8px'
  },
  buttonStyle:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    fontSize:'20px',
    fontWeight:700,
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    padding:'16px 0',
    borderRadius:'8px',
    textTransform:'none' as 'none'
  },
  anchorTagStyles:{
    color:'#925B5B',
    fontSize:'16px',
    fontWeight:600,
    lineHeight:'5px'
  },
  anchorResendBtn:{
    color:'#925B5B',
    fontSize:'24px',
    fontWeight:700,
    lineHeight:'15px',
    textDecoration:'underline',
    cursor: "pointer"
  },
  errorInputText:{
    color:'red',
    fontSize:'14px'
 }
 }
;
export default ForgotPasswordBlock;
 // Customizable Area End
