import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  createTheme,
  styled,
  Typography,
} from "@material-ui/core";
import { defaultAvatar } from "../../blocks/user-profile-basic/src/assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  shareIcon,
  commentIcon
} from "../../blocks/landingpage/src/assets";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2.web";
import { IPostData } from "../../blocks/landingpage/src/FeedController";
import Comments from "../../blocks/comments/src/Comments";
import ContentModeration from "../../blocks/contentmoderation/src/ContentModeration.web";

interface Props {
  description: string;
  liked: boolean;
  likeCount: number;
  index: number;
  likeBy: string;
  likeByUser: {
    id: number;
    email: string;
  };
  postByUser: {
    id: number;
    email: string;
  };
  userProfile: string;
  postMedia: IMedia[];
  commentCount: number;
  postId: number;
  navigationFn: (path: string, param?: string | number) => void;
  isRepost: boolean;
  repostData: {
    data: IPostData;
  };
  postData: IPostData;
  navigation: any
}

interface IMedia {
  id: number;
  url: string;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});

const StyledCarousel = styled(Carousel)({
  "& .control-arrow": {
    background: "none !important",
    opacity: "1 !important",
  },
  "& .dot": {
    margin: "0px 4px !important",
    height: "10px !important",
    width: "10px !important",
  },

  "&.reposted": {
    "& .carousel-slider": {
      width: "413px !important",
    },
  }
});


const PostCard = (props: Props) => {
  const {
    userProfile,
    postByUser,
    commentCount,
    description,
    index,
    liked,
    postId,
    postMedia,
    navigationFn,
    isRepost,
    repostData,
    postData,
    likeCount,
    likeByUser
  } = props;

  const [formatDescription, setformatDescription] = useState(description);
  const [showMore, setShowMore] = useState(false);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [mutedVideos, setMutedVideos] = useState<boolean[]>([true]);

  const formatDescriptionFn = () => {  
    if (description.length > 100) {
      setformatDescription(description.slice(0, 100));
    }
    else setformatDescription(description);
    setShowMore(false);
  };

  const handleExpand = () => {
    setformatDescription(description);
    setShowMore(true);
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target as HTMLVideoElement;
          if (entry.isIntersecting) {
            video.play();
          } else {
            video.pause();
          }
        });
      },
      { threshold: 0.75 }
    );

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    formatDescriptionFn()

    return () => {
      observer.disconnect();
    };
  }, []);

  
  const handleMuteToggle = (index: number) => {
    setMutedVideos((prevMutedVideos) => {
      const newMutedVideos = [...prevMutedVideos];
      newMutedVideos[index] = !newMutedVideos[index];
      return newMutedVideos;
    });
  };

  return isRepost ? (
    <Box width={"880px"} height={"463px"} marginBottom={"35px"} display={"flex"} style={{
      padding: "16px",
      justifyContent: "center",
      boxSizing: "border-box",
      gap: "12px"
    }}>
      <Box display={"flex"} flexDirection={"column"} width={"542px"} style={{gap: "12px"}}>
        <Box>
          <Box display={"flex"} alignItems={"center"}>
            <Avatar
              alt="profile"
              src={userProfile ?? defaultAvatar}
              style={{
                height: "44px",
                width: "44px",
                border: "2px solid #F3E1DC",
              }}
            />
            <Box>
              <Typography
                style={{
                  marginLeft: "16px",
                  color: "#0F172A",
                  fontSize: "16px",
                  fontWeight: 600,
                  cursor: "pointer"
                }}
                onClick={() => navigationFn("UserProfileBasicBlock", postByUser?.id)}
              >
                {postByUser?.email?.split("@")[0]} <span style={{ marginLeft: "5px" }}>•</span>
                <span
                  style={{
                    color: "#64748B",
                    fontSize: "16px",
                    marginLeft: "5px",
                  }}
                >
                  {postData?.attributes?.created_at}
                </span>{" "}
              </Typography>
              <Typography
                style={{ color: "#334155", fontSize: "16px" }}
              >
                {formatDescription}
                {description.length > 100 && <h5 style={{cursor: "pointer", margin: 0, fontSize: "16px", color: "#64748B", lineHeight: "24px"}}>
                    {showMore ? <span onClick={formatDescriptionFn}>Show less</span> : <span onClick={handleExpand}>Show more</span>}
                  </h5>}
              </Typography>
            </Box>
          </Box>
        </Box>

        <StyledCarousel width={"542px"} className="reposted" showThumbs={false} showArrows={repostData?.data?.attributes?.images_and_videos?.length > 1} showStatus={false} showIndicators={repostData?.data?.attributes?.images_and_videos?.length > 1}>
          {repostData?.data?.attributes?.images_and_videos.map((media, index) => {
            return new URL(media.url).searchParams.get("type") === "image" ? (
              <img
                src={media.url}
                style={{
                  height: "305px",
                  width: "100%",
                  borderRadius: "32px"
                }}
                alt=""
              />
            ) : (
              <video
                loop
                autoPlay
                ref={(el) => (videoRefs.current[index] = el)}
                muted={mutedVideos[index]}
                onClick={() => handleMuteToggle(index)}
                style={{
                  height: "305px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "32px"
                }}
              >
                <source src={media?.url} />
              </video>
            );
          })}
        </StyledCarousel>
      </Box>
      <Box style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"294px"} minHeight={"44px"}>
          <Box display={"flex"}>
            <Avatar
              alt="profile"
              src={repostData?.data?.attributes?.user_profile ?? defaultAvatar}
              style={{
                height: "44px",
                width: "44px",
                border: "2px solid #F3E1DC",
              }}
            />
            <Box display={"flex"} flexDirection={"column"}>
              <Typography
                style={{
                  marginLeft: "16px",
                  color: "#0F172A",
                  fontSize: "16px",
                  fontWeight: 600,
                  cursor: "pointer"
                }}
                onClick={() => navigationFn("UserProfileBasicBlock", repostData?.data?.attributes?.post_by_user?.id)}
              >
                {repostData?.data?.attributes?.post_by_user?.email?.split("@")[0]}
                <span style={{ marginLeft: "5px" }}>•</span>
                <span
                  style={{
                    color: "#64748B",
                    fontSize: "16px",
                    marginLeft: "5px",
                  }}
                >
                  {repostData?.data?.attributes?.created_at}
                </span>{" "}
              </Typography>
            </Box>
            <ContentModeration navigation={props.navigation} id={""} userId={repostData?.data?.attributes?.post_by_user?.id} userName={repostData?.data?.attributes?.post_by_user?.email?.split("@")[0]} postId={repostData?.data?.attributes.id} postByUser={postByUser} />
          </Box>
        </Box>
        <Box style={{ position: "relative", display: "flex", flexDirection: "column", gap: "12px" }} height={"315px"}>
            {repostData?.data?.attributes?.like_count > 0 && <Typography
              style={{ color: "#0F172A", fontSize: "16px" }}
            >
              Loved by
              <span style={{ fontWeight: 600, cursor: "pointer" }} onClick={() => navigationFn("UserProfileBasicBlock", repostData?.data?.attributes?.post_by_user?.id)}> {repostData?.data?.attributes?.like_by_user?.email?.split("@")[0]}</span>
              {repostData?.data?.attributes?.like_count > 1 && <span onClick={() => navigationFn("Loves", repostData?.data?.attributes?.id)} style={{ fontWeight: 600, cursor: "pointer" }}> and {repostData?.data?.attributes?.like_count - 1} others</span>}
            </Typography>}
          <Box height={"187px"} overflow={"auto"}>
            <Typography
            style={{ color: "#334155", fontSize: "16px" }}
          >
            {formatDescription}
            {description.length > 100 && <h5 style={{cursor: "pointer", margin: 0, fontSize: "16px", color: "#64748B", lineHeight: "24px"}}>
                {showMore ? <span onClick={formatDescriptionFn}>show less</span> : <span onClick={handleExpand}>more</span>}
              </h5>}
          </Typography>
          </Box>
          <Box display={"flex"} style={{ gap: "12px" }}>
            <Likeapost2
              navigation={undefined}
              id={""}
              postId={postId}
              isLiked={liked}
            />
            <img
              src={commentIcon}
              alt=""
              onClick={() => navigationFn("Repost", postId)}
              style={{ cursor: "pointer" }}
            />
            <img
              src={shareIcon}
              alt=""
              onClick={() => navigationFn("Repost", postId)}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Comments navigation={undefined} id={""} postId={postId} commentCount={commentCount} />
        </Box>
      </Box>
    </Box>
  ) : (
    <Box width={"880px"} height={"463px"} marginBottom={"35px"} display={"flex"} style={{
      padding: "16px",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      gap: "12px"
    }}>
      {<StyledCarousel width={"542px"} showThumbs={false} showArrows={postMedia.length > 1} showStatus={false} showIndicators={postMedia?.length > 1}>
        {postMedia.map((media, index) => {
          return new URL(media.url).searchParams.get("type") === "image" ? (
            <img
              src={media.url}
              style={{
                height: "431px",
                width: "542px",
                borderRadius: "32px"
              }}
              alt=""
            />
          ) : (
            <video
              loop
              autoPlay
              ref={(el) => (videoRefs.current[index] = el)}
              onClick={() => handleMuteToggle(index)}
              muted={mutedVideos[index]}
              style={{
                height: "431px",
                width: "542px",
                objectFit: "cover",
                borderRadius: "32px"
              }}
            >
              <source src={media?.url} />
            </video>
          );
        })}
      </StyledCarousel>}
      <Box display={"flex"} flexDirection={"column"} style={{ gap: "12px" }} height={"100%"}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"294px"} maxHeight={"44px"} height={"100%"}>
          <Box display={"flex"} alignItems={"center"}>
            <Avatar
              alt="profile"
              src={userProfile ?? defaultAvatar}
              style={{
                height: "44px",
                width: "44px",
                border: "2px solid #F3E1DC",
              }}
            />
            <Typography
              style={{
                marginLeft: "16px",
                color: "#0F172A",
                fontSize: "16px",
                fontWeight: 600,
                cursor: "pointer"
              }}
              onClick={() => navigationFn("UserProfileBasicBlock", postByUser?.id)}
            >
              {postByUser?.email?.split("@")[0]}
            </Typography>
          </Box>
          <ContentModeration navigation={props.navigation} postId={postId} id={""} userId={postByUser?.id} userName={postByUser?.email?.split("@")[0]} postByUser={postByUser} />
        </Box>
          {likeCount > 0 && <Typography
            style={{ color: "#0F172A", fontSize: "16px" }}
          >
            Loved by
            <span style={{ fontWeight: 600, cursor: "pointer" }} onClick={() => navigationFn("UserProfileBasicBlock", likeByUser?.id)}>&nbsp;{likeByUser?.email?.split("@")[0]} </span>

            {likeCount > 1 && <span style={{ fontWeight: 600, cursor: "pointer" }} onClick={() => navigationFn("Loves", postId)} >and {likeCount - 1} others</span>}
          </Typography>}
        <Box position={"relative"} height={"247px"} overflow={"auto"}>
          <Typography
            style={{ color: "#334155", fontSize: "16px" }}
          >
            {formatDescription}
            {description.length > 100 && <h5 style={{cursor: "pointer", margin: 0, fontSize: "16px", color: "#64748B", lineHeight: "24px"}}>
                {showMore ? <span onClick={formatDescriptionFn}>show less</span> : <span onClick={handleExpand}>more</span>}
              </h5>}
          </Typography>
        </Box>
        <Box display={"flex"} style={{ gap: "12px" }}>
          <Likeapost2
            navigation={undefined}
            id={""}
            postId={postId}
            isLiked={liked}
          />
          <img
            src={commentIcon}
            alt=""
            onClick={() => navigationFn("Repost", postId)}
            style={{ cursor: "pointer" }}
          />
          <img
            src={shareIcon}
            alt=""
            onClick={() => navigationFn("Repost", postId)}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Comments navigation={undefined} id={""} postId={postId} commentCount={commentCount} />
      </Box>
    </Box>
  );
};

export default PostCard;
