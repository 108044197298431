import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import DeleteConfirmationModal from "../../../components/src/DeleteConfirmationModal";
import CustomButton from "../../../components/src/CustomButton";
import { Box, FormControlLabel, Container, IconButton, InputAdornment, Typography as Mtypography, TextField, ThemeProvider, createTheme, styled } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { accountIcon, actions, changePwd, contact, deleteAcc, drafts, lockIcon, logout, navLinkIcon, notification, payments, personal, privacy } from "./assets";
import LogoutConfirmationModal from "../../../components/src/LogoutConfirmationModal";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import CustomToaster from "../../../components/src/CustomToaster";
import Loader from "../../../components/src/Loader.web";
import { checkboxIcon } from "../../email-account-registration/src/EmailAccountRegistrationController";
import CustomCheckbox from "../../../components/src/StyledCheckbox";

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});


const StyledBox = styled(Box)({
  "&.pwd-checkbox-container": {
    marginTop: "20px",
    paddingInline: "20px",
    "@media (max-width: 500px)": {
      paddingInline: "0px",
    },
  },

  "&.btn-container": {
    paddingInline: "20px", marginTop: "40px",
    display: "flex",
    "@media (max-width: 500px)": {
      paddingInline: "0px",
      flexDirection: "column"
    },
  },

  "&.label-container": {
    cursor: "pointer",
    paddingInline: "20px",
    borderRadius: "5px",
    marginBottom: "12px",
    "&:hover": {
      backgroundColor: "#F5F1EF",
    },
  },

  "&.active-tab-container": {
    backgroundColor: "#F5F1EF",
    padding: "16px 20px 16px 20px",
    cursor: "pointer",
    borderRadius: "8px",
    marginRight: "100px",
    "@media (max-width: 1000px)": {
      marginRight: "50px",
    },
    "@media (max-width: 500px)": {
      marginRight: "20px",
    },
    "@media (max-width: 700px)": {
      height: "25px",
      "& .tab-description": {
        display: "none",
      },
    },
    "& .tab-title": {
      color: "#925B5B",
      fontSize: "18px",
      fontWeight: 600,
      marginBottom: "10px",
      "@media (max-width: 525px)": {
        fontSize: "16px",
      },
    },
    "& .tab-description": {
      fontSize: "14px",
      color: "#334155",
      fontWeight: 400,
    },
  },

  "&.inactive-tab-container": {
    padding: "16px 20px 16px 20px",
    borderRadius: "8px",
    marginRight: "100px",
    cursor: "pointer",
    "@media (max-width: 1000px)": {
      marginRight: "50px",
    },
    "@media (max-width: 500px)": {
      marginRight: "20px",
    },
    "@media (max-width: 700px)": {
      height: "25px",
      "& .tab-description": {
        display: "none",
      },
    },
    "& .tab-title": {
      color: "#334155",
      fontSize: "18px",
      fontWeight: 600,
      marginBottom: "10px",
      "@media (max-width: 525px)": {
        fontSize: "16px",
      },
    },
    "& .tab-description": {
      fontSize: "14px",
      color: "#334155",
      fontWeight: 400,
    },
    "&:hover": {
      backgroundColor: "#F5F1EF",
      "& .tab-title": {
        color: "#925B5B",
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "10px",
        "@media (max-width: 525px)": {
          fontSize: "16px",
        },
      },
    },
  },

  "&.main-container": {
    marginTop: "25px",
    marginBottom: "100px",
    "@media (max-width: 700px)": {
      flexDirection: "column",
    },
  },
  "&.tabtitle-container": {
    marginTop: "25px",
    "@media (max-width: 700px)": {
      display: "flex",
    },
  },
});


const StyledTypography = styled(Mtypography)({
  "&.label-title": {
    fontSize: "24px",
    fontWeight: 600,
    color: "#0F172A",
    margin: "25px 0px",
    paddingInline: "20px",
    "@media (max-width: 1000px)": {
      paddingInline: "15px",
      fontSize: "20px",
    },
    "@media (max-width: 500px)": {

    },
  },
  "&.desc-menu": {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    color: "#0F172A",
    fontWeight: 400,
    margin: "10px 0px",
    "@media (max-width: 1000px)": {
      fontSize: "18px",
    },
    "@media (max-width: 525px)": {
      fontSize: "16px",
    },
    "& span": {
      marginLeft: "15px",
      "@media (max-width: 1000px)": {
        marginLeft: "10px"
      },
    },
  }
});


const StyledInputField = styled(TextField)({
  width: "542px",
  paddingInline: "20px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    padding: "10px 8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
  },
  "& .MuiInputBase-input": {
    padding: "10px 8px",
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #925B5B",
  },
  "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #925B5B",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    fontSize: "14px",
    color: "#DC2626",
  },
  "& .MuiOutlinedInput-adornedStart": {
    paddingLeft: "0 !important",
  },
  "& .MuiOutlinedInput-inputAdornedStart": {
    paddingLeft: "0 !important",
  },
  "@media (max-width: 600px)" : {
    width: "400px",
    paddingInline: "0px",
  },
  "@media (max-width: 425px)" : {
    width: "325px",
  }
});

const StyledFormLabel = styled(FormControlLabel)({
  "& .MuiTypography-body1": {
    "@media (max-width: 960px)": {
      fontSize: "18px",
    },
    "@media (max-width: 500px)": {
      fontSize: "16px",
    },
  }
})

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  accountTabList = [
    { id: 1, iconImg: personal, linkName: configJSON.accountLabels.PERSONAL_DETAILS, onClick: () => this.navigateToPath(configJSON.userProfile) },
    { id: 2, iconImg: changePwd, linkName: configJSON.accountLabels.CHANGE_PASSWORD, onClick: () => this.toggleChangePassword(true) },
    { id: 3, iconImg: notification, linkName: configJSON.accountLabels.NOTIFICATIONS },
    { id: 4, iconImg: privacy, linkName: configJSON.accountLabels.PRIVACY, onClick: () => this.togglePrivacySetting(true) },
    { id: 5, iconImg: contact, linkName: configJSON.accountLabels.CONTACT_LABEL, onClick: () => this.navigateToPath(configJSON.contactUs) },
    { id: 6, iconImg: drafts, linkName: configJSON.accountLabels.SAVED_DRAFTS },
    { id: 7, iconImg: payments, linkName: configJSON.accountLabels.MANAGE_PAYMENTS },
  ]

  actionAccountList = [
    { id: 1, iconImg: logout, linkName: configJSON.actionLabels.LOGOUT, onClick: () => this.toggleLogoutModal(true) },
    { id: 2, iconImg: deleteAcc, linkName: configJSON.actionLabels.DELETE_ACCOUNT, onClick: () => this.toggleDeleteConfirmationModal(true) },
  ]


  renderPasswordCriteria() {
    const { hasUpperCase, hasSpecialChar, hasNumber, hasMinLength } = this.state;

    return (
      <Box display={"flex"} flexDirection={"column"} style={{gap: "8px"}}>
        <div style={webStyles.passwordCriteria}>{hasUpperCase ? checkboxIcon.pass : checkboxIcon.fail} At least one upper case and one lower case letter in password field</div>
        <div style={webStyles.passwordCriteria}>{hasSpecialChar ? checkboxIcon.pass : checkboxIcon.fail} At least one special character</div>
        <div style={webStyles.passwordCriteria}>{hasNumber ? checkboxIcon.pass : checkboxIcon.fail} At least one number</div>
        <div style={webStyles.passwordCriteria}>{hasMinLength ? checkboxIcon.pass : checkboxIcon.fail} Minimum character length is 8 characters</div>
      </Box>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu navigation={this.props.navigation} id={"1"} />
        <Container style={webStyles.containerStyle}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton
              data-test-id="btn-back"
              style={webStyles.IconBtnStyle}
              onClick={() => { this.handleCancelClick()
              }}
            >
              <ArrowBackIcon htmlColor="#fff" />
            </IconButton>
            <Mtypography style={webStyles.titleStyle}>
              {configJSON.settingsTitle}
            </Mtypography>
          </Box>
          <StyledBox className="main-container" display={"flex"}>
            <StyledBox className="tabtitle-container">
              <StyledBox
                display={"flex"}
                alignItems={"flex-start"}
                data-test-id="accountsTab"
                marginBottom={"15px"}
                className={
                  this.state.currActiveTab === configJSON.accountTab
                    ? "active-tab-container"
                    : "inactive-tab-container"
                }
                onClick={() => this.handleTabChange(configJSON.accountTab)}
              >
                <img src={accountIcon} alt="" />
                <Box marginLeft={"10px"}>
                  <Mtypography className="tab-title">
                    {configJSON.accountTab}
                  </Mtypography>
                  <Mtypography className="tab-description">
                    {configJSON.accountDescription}
                  </Mtypography>
                </Box>
              </StyledBox>
              <StyledBox
                display={"flex"}
                className={
                  this.state.currActiveTab === configJSON.actionsTab
                    ? "active-tab-container"
                    : "inactive-tab-container"
                }
                alignItems={"flex-start"}
                data-test-id="actions-tab"
                onClick={() => this.handleTabChange(configJSON.actionsTab)}
              >
                <img src={actions} alt="" />
                <Box marginLeft={"10px"}>
                  <Mtypography className="tab-title">
                    {configJSON.actionsTab}
                  </Mtypography>
                  <Mtypography className="tab-description">
                    {configJSON.actionDescription}
                  </Mtypography>
                </Box>
              </StyledBox>
            </StyledBox>
            {!this.state.isChangePwdActive && !this.state.isPrivacyActive  && (
              <Box width={"100%"}>
                {this.state.currActiveTab === configJSON.accountTab ? (
                  <StyledBox className="tab-desc">
                    <StyledTypography className="label-title">
                      {configJSON.accountSettings}
                    </StyledTypography>
                    {this.accountTabList.map((label) => {
                      return (
                        <StyledBox
                          className="label-container"
                          key={label.id}
                          display={"flex"}
                          data-test-id={`setting-label${label.id}`}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          onClick={() => label.onClick && label.onClick()}
                        >
                          <StyledTypography className="desc-menu">
                            <img src={label.iconImg} alt="" />
                            <span>{label.linkName}</span>
                          </StyledTypography>
                          <img
                            src={navLinkIcon}
                            style={webStyles.navIconStyle}
                            alt=""
                          />
                        </StyledBox>
                      );
                    })}
                  </StyledBox>
                ) : (
                  <StyledBox className="tab-desc">
                    <StyledTypography className="label-title">
                      {configJSON.accountActions}
                    </StyledTypography>
                    {this.actionAccountList.map((label) => {
                      return (
                        <StyledBox
                          className="label-container"
                          key={label.id}
                          data-test-id={`action-label${label.id}`}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          onClick={() => label.onClick && label.onClick()}
                        >
                          <StyledTypography
                            style={label.id === 2 ? { color: "#DC2626" } : {}}
                            className="desc-menu"
                          >
                            <img src={label.iconImg} alt="" />
                            <span style={webStyles.linkNameStyle}>
                              {label.linkName}
                            </span>
                          </StyledTypography>
                          <img
                            src={navLinkIcon}
                            style={webStyles.navIconStyle}
                            alt=""
                          />
                        </StyledBox>
                      );
                    })}
                  </StyledBox>
                )}
              </Box>
            )}
            {this.state.isChangePwdActive && (
              <Box width={"100%"}>
                <Mtypography style={webStyles.labelTitleStyle}>
                  {configJSON.changePassword}
                </Mtypography>
                <Mtypography style={webStyles.changePwdStyle}>
                  {configJSON.changePwdSublabel}
                </Mtypography>
                <ThemeProvider theme={theme}>
                  <label style={webStyles.labelText} htmlFor="password">
                    {configJSON.oldPwdText}
                  </label>
                  <StyledInputField
                    data-test-id="oldPwd"
                    variant={configJSON.outlined}
                    type={
                      this.state.showPassword
                        ? configJSON.textType
                        : configJSON.pwdType
                    }
                    value={this.state.oldPwd}
                    fullWidth
                    placeholder={configJSON.oldPwdPlaceHolder}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      this.handleOldPwdChange(event);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={lockIcon}
                            alt=""
                            style={webStyles.iconStyle}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <Visibility style={webStyles.iconStyle} />
                            ) : (
                              <VisibilityOff style={webStyles.iconStyle} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <label style={webStyles.labelText} htmlFor="password">
                    {configJSON.newPwdText}
                  </label>
                  <StyledInputField
                    variant={configJSON.outlined}
                    data-test-id="newPwd"
                    type={
                      this.state.showNewPassword
                        ? configJSON.textType
                        : configJSON.pwdType
                    }
                    value={this.state.newPwd}
                    fullWidth
                    placeholder={configJSON.newPwdPlaceHolder}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      this.handleNewPwdChange(event);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={lockIcon}
                            alt=""
                            style={webStyles.iconStyle}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleToggleNewPasswordVisibility}
                            edge="end"
                          >
                            {this.state.showNewPassword ? (
                              <Visibility style={webStyles.iconStyle} />
                            ) : (
                              <VisibilityOff style={webStyles.iconStyle} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <StyledBox className="pwd-checkbox-container">
                    {this.renderPasswordCriteria()}
                  </StyledBox>
                </ThemeProvider>
                <StyledBox className="btn-container">
                  <CustomButton
                    variant="outlined"
                    color="primary"
                    fullWidth
                    dataTestId="btn-cancel"
                    btnText={configJSON.cancel}
                    style={webStyles.changePwdBtnStyle}
                    onClick={() => this.toggleChangePassword(false)}
                  />
                  <CustomButton
                    variant="contained"
                    data-test-id="copy-icon"
                    color="primary"
                    fullWidth
                    dataTestId="btn-change"
                    btnText={configJSON.changePassword}
                    disabled={this.state.isPasswordInvalid}
                    onClick={this.changePwd}
                    style={webStyles.changePwdBtnStyle}
                  />
                </StyledBox>
              </Box>
            )}
            {this.state.isPrivacyActive && (
              <Box width={"100%"} style={{
                paddingInline: "20px"
              }}>
                <Mtypography style={webStyles.labelTitleStyle}>
                  {configJSON.privacySetting}
                </Mtypography>
                <ThemeProvider theme={theme}>
                  <Box style={{ flexDirection: "column", display: "flex", gap: "18px" }}>
                    {this.privacySettings.map((el, i) => (
                      <Box
                        key={`privacy-setting-${i}`}
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          flexDirection: "column",
                          display: "flex",
                          gap: "18px",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            margin: 0,
                          }}
                        >
                          {el}
                        </p>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                          {this.options.map((item, indx) => (
                            <StyledFormLabel
                              key={`option-${i}-${indx}`}
                              data-test-id="control-label"
                              control={
                                <CustomCheckbox
                                  data-test-id="privacy-checkbox"
                                  checked={
                                    this.state.selectedPrivacySettings[i] === item
                                  }
                                  onChange={() => this.handleCheckboxChange2(i, item)}
                                  name={`${el}-${item}-${indx}`}
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                  }}
                                />
                              }
                              label={item}
                            />
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </ThemeProvider>
              </Box>
            )}
          </StyledBox>
          <DeleteConfirmationModal
            isOpen={this.state.isConfirmationModalOpen}
            handleClose={this.toggleDeleteConfirmationModal}
            successCallback={this.deleteAccount}
          />
          <LogoutConfirmationModal
            isOpen={this.state.isLogoutModalOpen}
            handleClose={this.toggleLogoutModal}
            successCallback={this.logoutUser}
          />
          <CustomToaster/>
          <Loader isLoading={this.state.isLoading}/>
        </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles : { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px" },
  changePwdBtnStyle: {
    marginRight: "15px",
    marginTop: "15px"
  },
  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },
  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
  },
  navIconStyle: {
    cursor: "pointer"
  },

  activeTabDescription: {
    fontSize: "14px",
    color: "#334155",
    fontWeight: 400
  },

  activeTabContainer: {
    backgroundColor: "#F5F1EF",
    padding: "16px 20px 16px 20px",
    borderRadius: "8px",
    marginRight: "100px"
  },
  inactiveTabContainer: {
    padding: "16px 20px 16px 20px",
    borderRadius: "8px",
    marginRight: "100px"
  },

  labelStyle: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    color: "#0F172A",
    fontWeight: 400,
    margin: "10px 0px"
  },
  mainContainer: {
    marginTop: "25px",
    marginBottom: "100px"
  },

  labelText:{
    fontSize:'14px',
    lineHeight:'24px',
    fontWeight:600,
    color: "#334155",
    paddingInline: "20px",
    display: "block",
    marginTop: "40px"
  },

  labelTitleStyle: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#0F172A",
    margin: "25px 0px",
  },
  changePwdStyle: {
    margin: "20px 0px",
    paddingInline: "20px",
    fontSize: "16px",
    color: "#282627",
    fontWeight: 400
  },

  activeTabTitle: {
    color: "#925B5B",
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "10px"
  },
  inactiveTabTitle: {
    color: "##334155",
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "10px"
  },
  iconStyle: {
    marginInline: "10px"
  },
  passwordCriteria:{
    display :'flex',
    gap:'5px',
    alignItems:'center'
  },
};

// Customizable Area End
