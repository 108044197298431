Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleTxt = "LandingPage";
exports.labelBodyTxt = "LandingPage Body";
exports.btnExampleTitle = "CLICK ME";
exports.allPosts = "All Posts";
exports.apiContentType = "application/json";

// Method type
exports.methodTypes = {
  HTTP_GET: "GET",
  HTTP_POST: "POST",
  HTTP_PUT: "PUT",
  HTTP_DELETE: "DELETE"
}


// Endpoints
exports.endpoints = {
  GET_POST: "bx_block_posts/posts",
  GET_POST_BY_ID: "bx_block_posts/posts/get_post_by_category"
}


// Constants

exports.tokenExpireMsg = "Token has Expired";
exports.unauthorizedMsg = "Your session has expired, Please login again.";
exports.immortalizeMoments = "Immortalize your most precious moments";

exports.btnText = "Celebrate Love";
exports.emailAccountRegistration = "EmailAccountLoginBlock";
exports.chooseCategory = "Choose any category";

exports.immortalize = "Immortalize your most precious moments";
exports.introduction = `Extraordinal Love elevates personal tales of emotion and love
into everlasting treasures, meticulously preserved through a
digital archive in the cloud and secured on the blockchain for
generational endurance. We intertwine these narratives with
distinctive, uniquely identified jewelry, forging a tangible
link to your most treasured memories, and introduce a community
app of social reference that celebrates the transformative power
of love. Join us in immortalizing your most precious moments!`;

exports.heartfelt = "Heartfelt Inspiration";
exports.inspirationOne = `We preserve cherished memories with our unique combination of
digital archiving, community sharing, and heirloom jewelry.`;

exports.inspirationTwo = `We’ve created a platform, a work of art, and a community around
the most powerful emotion in life—love. With core values rooted in
empathy, innovation, permanence and transparency, we aim to serve
those who hold love dear. Through Extraordinal Love, love isn't
just felt; it's etched in eternity.`;

exports.inspirationThree = `Our mission extends beyond crafting exquisite jewelry; we weave
narratives of affection that transcend time. At the heart of our
offering is the preservation of personal love stories through our
primary product: the digital archive. Each piece of jewelry serves
as a vessel, capturing not just stories, but also the emotions of
those who cherish love's enduring significance.`;


exports.ourProducts = "Our Products";
exports.digitalArchival = "Digital Archive";
exports.archivalDescription = `Transcending time, your love stories are digitally archived
for generations to come.`;

exports.community = "Community";
exports.communityDesc = `Social network for celebrating special moments with your loved
ones or broader community.`;

exports.handcrafted = "Handcrafted Jewelry";
exports.handcraftedJwellery = "Handcrafted Jewelry";
exports.jewellryDescription = `Heirloom, statement-pieces of art, embedded with the defining
moments of your journey.`;

exports.founderQuote = "Founder’s Quote:";
exports.quoteDescription = `“Once you show someone your love, it becomes part of their being,
it can never be lost. As such, may we all find ourselves lost in a
perpetual contemplation of an infinite love.”`;

exports.founderName = "Matthew Benfer";
exports.founderDesignation = "Chief Archival Curator Extraordinal Love";

exports.craftLegacy = "Craft your Legacy";

exports.validationApiContentType = "application/json"
exports.crafted = "Handcrafted Jewelry";
exports.description = `Heirloom, statement-pieces of art, embedded with the defining
moments of your journey.`;

exports.quote = "Founder’s Quote:";
exports.description = `“Once you show someone your love, it becomes part of their being,
it can never be lost. As such, may we all find ourselves lost in a
perpetual contemplation of an infinite love.”`;




// Customizable Area End