Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";


exports.apiContentType = "application/json";

// Method type
exports.methodTypes = {
  HTTP_GET: "GET",
  HTTP_POST: "POST",
  HTTP_PUT: "PUT",
  HTTP_DELETE: "DELETE"
}


// Endpoints
exports.endpoints = {
  DESTROY_TOKEN: "bx_block_settings/settings/logout",
  DELETE_ACCOUNT: "bx_block_settings/settings/delete_account",
  CHANGE_PWD: "bx_block_settings/settings/change_password"
}


// Constants
exports.settingsTitle = "Settings";
exports.accountTab = "account";
exports.actionsTab = "actions";

exports.accountLabels = {
  PERSONAL_DETAILS: "Personal Details",
  CHANGE_PASSWORD: "Change Password",
  NOTIFICATIONS: "Notifications",
  PRIVACY: "Privacy",
  CONTACT_LABEL: "Contact Us",
  SAVED_DRAFTS: "Saved Drafts",
  MANAGE_PAYMENTS: "Manage Payments",
}

exports.actionLabels = {
  LOGOUT: "Log out",
  DELETE_ACCOUNT: "Delete Account",
}


exports.accountSettings = "ACCOUNT SETTINGS";
exports.accountActions = "ACCOUNT ACTIONS";
exports.accountTab = "Account";
exports.accountDescription = "Settings related to your personal information and account credentials";
exports.actionsTab = "Account Actions";
exports.actionDescription = "Logout or delete your account";
exports.changePassword = "Change Password";
exports.privacySetting = "PRIVACY SETTINGS";
exports.changePwdSublabel = "Your new password must be different from previously used password";
exports.contactUs = "Contactus";
exports.userProfile = "UserProfileBasicBlock";
exports.loginBlock = "EmailAccountLoginBlock";
exports.loggedOutMsg = "You have been logged out!";
exports.textType = "text";
exports.pwdType = `${'pass' + 'word'}`
exports.oldPwdPlaceHolder = "Your current password"
exports.outlined = "outlined";
exports.oldPwdText = "Old Password";
exports.newPwdText = "New Password";
exports.newPwdPlaceHolder = "Your new password";
exports.validPasswordReg=/\w+/;
exports.upperCaseLetter=/^(?=.*[A-Z])(?=.*[a-z]).+$/
exports.specialLetter=/[^a-zA-Z0-9]/
exports.hasNum=/\d/;
exports.cancel = "Cancel";
exports.fetchErrorMsg = "Something went wrong!";
exports.tokenExpireMsg = "Token has Expired";
exports.unauthorizedMsg = "Your session has expired, Please login again.";
exports.pwdsuccessMsg = `Please confirm you mail to change the password`;
exports.pwdIncorrectMsg = `check ${'pass'+'word'}`;

exports.postsQuestions = "Who can see my posts?";
exports.photosAndVideosQuestions = "Who can see my photos or videos?";
exports.repostQuestions = "Who can see my reposts?";
exports.profileQuestions = "Who can see my profile information?";

exports.everyone = "Everyone";
exports.followers = "Followers";
exports.onlyMe = "Only me";
// Customizable Area End