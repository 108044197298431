import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CustomButton from "./CustomButton";
import CloseIcon from "@material-ui/icons/Close";
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';

interface Props {
  isOpen: boolean;
  handleClose: (toggle: boolean) => void;
  reportPost: (reasonId: number, postId: number | undefined) => void;
  reportData: any;
  postId: number|undefined
}

const StyledBox = styled(Box)({
  "&.label-container": {
    marginBottom: "15px",
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F1EF",
    },
  },
  "&.selected-label-container": {
    marginBottom: "15px",
    padding: "10px",
    cursor: "pointer",
    backgroundColor: "#F5F1EF",
  }
});


const ReportPostModal = (props: Props) => {
  const { isOpen, handleClose, reportData } = props;
  const [selectedReason, setSelectedReason] = useState<Number | null>(null);

  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            overflow: "hidden"
          },
        },
      },
    },
  });
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth
        maxWidth="sm"
      style={{borderRadius: "12px"}}
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <IconButton
            aria-label="close"
            onClick={() => handleClose(false)}
            style={{ position: "absolute", top: 5, right: 5 }}
          >
            <CloseIcon htmlColor="#94A3B8" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ maxHeight: "525px" }}>
          {reportData?.map((item: any) => {
            return (
              <>
                <StyledBox className={selectedReason === item.id ? "selected-label-container" : "label-container"} onClick={() => setSelectedReason(item.id)}>
                  <ul style={{ marginLeft: "-25px" }}><li style={{ color: "#003249", fontWeight: 700 }}>{item?.attributes?.title}</li></ul>
                  <Typography style={{ color: "#475569", fontSize: "14px" }}>
                    {item?.attributes?.text_content}
                  </Typography>
                </StyledBox>
              </>
            )
          })}
        </DialogContent>
        <DialogActions>
          <CustomButton
            variant="contained"
            onClick={() => {
              handleClose(false);
              props.reportPost(Number(selectedReason), props.postId);
              setSelectedReason(null)
            }}
            disabled={selectedReason === null}
            color="primary"
            data-test-id="report-btn"
            btnText="Done"
            style={{
              margin: "10px 15px",
              height: "56px",
              fontSize: "16px",
              width: "100%"
            }}
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ReportPostModal;
