import React from "react";
// Customizable Area Start
import { Typography, styled, Popover } from "@material-ui/core";

import ContentModerationController, {
  configJSON,
} from "./ContentModerationController";
import { optionIcon } from "../../../blocks/landingpage/src/assets";
import { followIcon, reportIcon, unfollowIcon, viewProfile } from "./assets";
import ReportPostModal from "../../../components/src/ReportPostModal";
import CustomToaster from "../../../components/src/CustomToaster";
import UnfollowConfirmationModal from "../../../components/src/UnfollowConfirmationModal";


const StyledPopover = styled(Popover)({
  "& .MuiPaper-rounded": {
    padding: "10px",
    borderRadius: "8px"
  },
});
// Customizable Area End  

class ContentModeration extends ContentModerationController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        <img
          src={optionIcon}
          alt=""
          data-test-id="pop-over-option"
          style={{ cursor: "pointer" }}
          aria-describedby={
            Boolean(this.state.anchorEl) ? "simple-popover" : undefined
          }
          onClick={this.handlePopoverClick}
        />
        <StyledPopover
          data-test-id="popover-wrapper"
          id={Boolean(this.state.anchorEl) ? "simple-popover" : undefined}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopverClose}
          anchorPosition={{ left: 75, top: 100 }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          disableScrollLock={false} 
          disableRestoreFocus={true}
        >
          <Typography style={webStyle.contentItem} data-test-id="view-profile" onClick={() => this.navigateToPage("UserProfileBasicBlock", this.props.userId)}>
            <img src={viewProfile} style={webStyle.popoverIcon} /> {configJSON.moderationList.VIEW_PROFILE}
          </Typography>
          <Typography
            style={webStyle.contentItem}
            onClick={() => this.toggleReportModal(true)}
            data-test-id="report-post"
          >
            <img src={reportIcon} style={webStyle.popoverIcon} />
            {configJSON.moderationList.REPORT_POST}
          </Typography>
          {this.showFollowUserOption() && (this.props.isFollowing ? (
            <Typography
              style={{ ...webStyle.contentItem, color: "#DC2626" }}
              onClick={() => this.toggleUnfollowModal(true)}
              data-test-id="unfollow-user"
            >
              <img src={unfollowIcon} style={webStyle.popoverIcon} />
              {configJSON.moderationList.UNFOLLOW_USER}
            </Typography>
          ) : (
            <Typography
              style={this.state.isRequestSuccess ? webStyle.requestedFont : webStyle.contentItem1}
              data-test-id="follow-user"
              onClick={this.followUser}
            >
              <img src={followIcon} style={webStyle.popoverIcon} />
              {this.state.isRequestSuccess ? configJSON.moderationList.REQUESTED : configJSON.moderationList.FOLLOW_USER}
            </Typography>
          ))}
        </StyledPopover>
        
        {this.state.isReportModalOpen && <ReportPostModal
          isOpen={this.state.isReportModalOpen}
          handleClose={this.toggleReportModal}
          reportPost={this.reportPost}
          reportData={this.state.reportData}
          postId={this.props.postId}
        />}
        <UnfollowConfirmationModal
          isOpen={this.state.isUnfollowModalOpen}
          handleClose={this.toggleUnfollowModal}
          unfollowUser={this.unFollowUser}
          userName={String(this.props.userName)}
        />
        <CustomToaster />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const webStyle : { [key: string]: React.CSSProperties } = {
  requestedFont: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: "14px",
    marginBottom: "10px",
    cursor: "not-allowed",
    color: "#334155",
    opacity: "0.7"
  },
  contentItem: {
    display: "flex",
    alignItems: "center",
    color: "#334155",
    fontWeight: 600,
    fontSize: "14px",
    marginBottom: "10px",
    cursor: "pointer"
  },
  contentItem1: {
    display: "flex",
    alignItems: "center",
    color: "#334155",
    fontWeight: 600,
    fontSize: "14px",
    cursor: "pointer"
  },
  popoverIcon: {
    height: "16px",
    width: "16px",
    marginRight: "5px"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

export default ContentModeration;

// Customizable Area End
